import React, { Fragment, useState } from 'react';

import chevron from '../images/icons/chevron.svg';

const FAQ_LIST = [
  {
    question: 'Что такое ДМС?',
    answer: 'Это добровольное медицинское страхование. По полису ДМС в случае болезни можно получить медицинскую помощь в платной клинике. Расходы на посещение врача, необходимую диагностику и анализы оплатит страховая компания.'
  },
  {
    question: 'Чем ДМС лучше ОМС?',
    answer: 'Основное отличие – набор медицинских учреждений. По ОМС можно посетить только государственные поликлиники. По полису ДМС страховые компании предлагают перечень коммерческих медицинских учреждений. Зачастую, за счет этого, по ДМС уровень комфорта пациента выше. А также время ожидания в записи к специалисту значительно ниже.'
  },
  {
    question: 'Какие виды медицинских услуг покрывает предлагаемый вами ДМС?',
    answer: 'Полис ДМС покрывает амбулаторно-поликлинические услуги, стационарное лечение, диагностику, лабораторные исследования, стоматологию, услуги скорой помощи и другое. Перечень конкретных услуг может варьироваться в зависимости от выбранной программы.'
  },
  {
    question: 'Какие медицинские учреждения входят в список?',
    answer: 'Перечень медицинских учреждений зависит от выбранной страховой компании и программы ДМС. В него могут частные клиники, а также ведущие государственные и муниципальные медицинские учреждения.'
  },
  {
    question: 'Каковы сроки заключения договора по ДМС?',
    answer: 'Заключение договора может занимать от нескольких дней до недели, в зависимости от объема информации, которую необходимо обработать. Этот срок включает согласование условий и стоимости страхования с вами, оформление документации, страховой компанией и подписание договора страхования.'
  },
  {
    question: 'Какова стоимость полиса ДМС на одного сотрудника?',
    answer: 'Стоимость ДМС для одного сотрудника может начинается от 10 тысяч рублей в год. И зависит от количества страхуемых сотрудников, регионах проживания сотрудников и выбранной программы страхования. Стоимость можно узнать с помощью калькулятора на нашем сайте или при помощи менеджера.'
  },
  {
    question: 'Есть ли скидки при заключении договора на группу сотрудников?',
    answer: 'Как таковой скидки не предусмотрено. Но чем больше численность застрахованных сотрудников в компании, тем ниже будет цена на одного сотрудника.'
  },
  {
    question: 'Каков порядок оплаты полиса ДМС и есть ли рассрочка (единовременно, помесячно и т.д.)?',
    answer: 'Для совсем небольших коллективов из нескольких человек зачастую доступна только полная оплата стоимости договора при его заключении. Для компаний с численность от 20-30 человек возможна поквартальная оплата. При оформлении договора наш менеджер расскажет обо всех возможных условиях оплаты и рассрочках.'
  },
  {
    question: 'Возможно ли застраховать сотрудников, работающих, как ИП, самозанятый, по договору ГПХ?',
    answer: 'Да, таких сотрудников также можно застраховать за счет компании.'
  },
  {
    question: 'Возможно ли застраховать не всех трудоустроенных сотрудников, а только часть?',
    answer: 'Да, такая возможность есть. Но в компании обязательно должен присутствовать локальный нормативный акт, который содержит критерии, при соответствии, котором работник получит ДМС. Критерии должны быть связаны, например, со стажем, квалификацией, видами работы и т.д., то есть ни в коем случае не с личными качествами вашего сотрудника. В противном случае оформление ДМС не на весь коллектив может быть расценено, как нарушение Трудового кодекса (дискриминация работника на основании ст. 3 ТК РФ). При этом у работодателя нет обязанности по страхованию сотрудников, оформленных как ИП, самозанятые или по договорам ГПХ.'
  },
  {
    question: 'Можно ли добавить членов семьи сотрудников в полис ДМС?',
    answer: 'Да, многие программы ДМС предусматривают возможность страхования членов семьи сотрудников, однако это будет увеличивать общую стоимость полиса.'
  },
  {
    question: 'Какие действия нужно предпринять в случае изменения состава сотрудников?',
    answer: 'Заявить об изменении состава застрахованных сотрудников можно легко и просто с помощью нашего портала «Кубики». Достаточно заполнить форму заявки и отслеживать статус изменения в системе. С помощью портала «Кубики» у вас под рукой всегда будет актуальная информация о списке застрахованных сотрудников, деталях договора страхования, списке доступных медицинских учреждениях и многое другое.'
  },
  {
    question: 'Возможны ли изменения в договоре после его заключения?',
    answer: 'Да, изменения могут быть внесены по доступным программа страхования, списку лечебных учреждений и другим параметрам. Для внесения изменения вам достаточно обратиться к нашему менеджеру и специалист организует все необходимые процессы и действия.'
  },
  {
    question: 'Есть ли возможность выбрать конкретные клиники или врачей?',
    answer: 'Да, вы можете выбрать любое медицинское учреждение из числа доступных по вашему полису. ',
    type: 'additional',
  },
  {
    question: 'Какие ограничения есть по числу обращений к врачам?',
    answer: 'В зависимости от программы, могут быть ограничения на число визитов к специалистам в течение года, на количество диагностических процедур или анализов. Подробная информация указывается в полисе.',
    type: 'additional',
  },
  {
    question: 'Какие услуги считаются нестраховыми?',
    answer: 'Нестраховыми услугами могут считаться косметические процедуры, лечение, не связанное с медицинскими показаниями, альтернативная медицина, а также медицинская помощь в организациях, которые не включены в программу страхования. Перед посещением клиники обязательно проверьте входит ли она в список доступных по полису ДМС, если сомневаетесь – уточните у вашей страховой компании.',
    type: 'additional',
  },
  {
    question: 'Какие документы нужно предоставить в клинике для получения медицинской помощи по ДМС?',
    answer: 'Обычно достаточно предъявить удостоверение личности. В некоторых случаях может потребоваться полис ДМС. Перед посещением клиники обязательно проверьте входит ли она в список доступных по полису ДМС, если сомневаетесь – уточните у вашей страховой компании.',
    type: 'additional',
  },
  {
    question: 'Какие дополнительные услуги можно включить в полис ДМС?',
    answer: 'Базовое покрытие включает в себя поликлиническое обслуживание и помощь врача на дому. В договор, за дополнительную плату, можно включить стоматологические услуги при острой зубной боли, экстренную и плановую госпитализацию.',
    type: 'additional',
  },
  {
    question: 'Возможно ли получение услуг за пределами РФ?',
    answer: 'Для получения медицинской помощи за пределами России необходимо оформление полиса выезжающего за рубеж (ВЗР). Это не трудоемкий процесс, однако он ведет к увеличению стоимости договора страхования.',
    type: 'additional',
  },
  {
    question: 'Какие условия по профилактическим мероприятиям включены в полис?',
    answer: 'Профилактические мероприятия могут включать диспансеризацию, вакцинацию, консультации специалистов по вопросам здоровья и другие услуги, направленные на профилактику заболеваний.',
    type: 'additional',
  },
  {
    question: 'Есть ли возможность получать консультации по здоровью по телефону или онлайн?',
    answer: 'Многие страховые компании предлагают телемедицинские консультации, которые включены в полис ДМС. Это может включать круглосуточную поддержку врачей по телефону или видеосвязи.',
    type: 'additional',
  },
  {
    question: 'Каковы условия расторжения договора и возврата денежных средств?',
    answer: 'Условия расторжения договора и возврата денежных средств прописываются в договоре. Обычно средства возвращаются пропорционально неиспользованному периоду страхования за вычетом административных расходов.',
    type: 'additional',
  },
]

const Answers: React.FC = () => {
  const keys = FAQ_LIST.map(item => [item.question, false]);
  const initialOpen = Object.fromEntries(keys);

  const [open, setOpen] = useState(initialOpen);



  const handleClick = (questionText: string) => {
    const newOpen = {
      ...open,
      [questionText]: !open[questionText]
    }

    setOpen(newOpen)
  }

  return (
    <section className="answers__section">
      <div className="container">
        <div className="answers__container">
          <h2 className="title answers__title">Ответы на вопросы</h2>
          <div className="answers__accordion">
            {FAQ_LIST.map((item, index) => {
              return (
                <Fragment key={item.question}>
                  <button className="answers__accordion-btn" onClick={() => handleClick(item.question)}>
                    {item.question} <img className="answers__chevron" src={chevron} alt="" />
                  </button>
                  {open[item.question] && <div className="answers__accordion-panel">
                    <p>{item.answer}</p>
                  </div>}

                  {index !== FAQ_LIST.length - 1 && <div className="line" />}

                </Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Answers;
