import React, { useState } from 'react';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import { message } from 'react-message-popup'
import Modal from './ui/modal/modal.tsx';

import flagRus from '../images/icons/flag-rus.svg';
import mts from '../images/icons/mts.svg';
import { ENV } from '../common/constants.tsx';
import { DisabledSteps, Form } from './calculate/calculate.tsx';
import Progress, { StepTypeEnum } from './calculate/progress.tsx';

const PHONE_DIGITS_COUNT = 10;

const OPTIONS = [
  {
    value: 'phone',
    label: 'Позвонить'
  },
  {
    value: 'email',
    label: 'Еmail'
  },
  {
    value: 'whatsUp',
    label: 'WhatsApp'
  },
  {
    value: 'telegram',
    label: 'Telegram'
  },
]

const isEmail = (value: string) => value === 'email';

interface IContactWidgetProps {
  form: Form,
  step: StepTypeEnum;
  stepOptions: Array<{ index: number; value: StepTypeEnum, label: string }>;
  disabledSteps: DisabledSteps;
  onStepChange: (value: StepTypeEnum) => void,
  onSetForm: (field: string, value: any) => void,
  onSetFormValues: (values) => void,
  onFinish: () => void,
}

const ContactWidget: React.FC<IContactWidgetProps> = (props) => {
  const { form, form: { email = '', contactType, insurerName }, step, stepOptions, disabledSteps,
    onStepChange, onSetForm, onSetFormValues, onFinish } = props;

  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState<boolean>(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const [isSuccessfulShowing, setIsSuccessfulShowing] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSubmitButtonActive = () => {
    switch (contactType) {
      case 'email': {
        const isEmailValid = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/);

        return isEmailValid && isPolicyChecked
      }
      case 'phone':
      case 'whatsUp':
      case 'telegram': {
        const value = form[contactType] ? form[contactType] : '';
        const formattedPhone = value.replace(/[^\d]/g, '');

        return formattedPhone.length === PHONE_DIGITS_COUNT && isPolicyChecked;
      }
      default:
        return false
    }

  }

  const handleContactTypeChange = (value) => (event) => {
    const values = {
      contactType: value
    }
    onSetFormValues(values)
  }


  const handlePhoneChange = (field) => (value: string) => {
    onSetForm(field, value)
  }

  const handleEmailChange = (event) => {
    onSetForm('email', event.target.value)
  }

  const handlePolicyChange = () => {
    setIsPolicyChecked(!isPolicyChecked);
  }

  const handleAgreementModalOpen = (event) => {
    event.preventDefault();

    setIsAgreementModalOpen(true)
  }

  const handleAgreementModalClose = () => {
    setIsAgreementModalOpen(false)
  }

  const handlePolicyModalOpen = (event) => {
    event.preventDefault();

    setIsPolicyModalOpen(true)
  }

  const handlePolicyModalClose = () => {
    setIsPolicyModalOpen(false)
  }

  const handleConfirm = async (event) => {
    event.preventDefault()

    const value = form[form.contactType] ? form[form.contactType] : '';

    const contactData = {
      [form.contactType]: isEmail(form.contactType) ? value : value.replace(/[^\d]/g, '')
    }

    const data = {
      cityNames: [...form.cityNames, ...form.newCities.map(item => item.value)],
      employeesCount: +form.employeesCount,
      medicalCareTypes: form.medicalCareTypes,
      tariffPlan: form.tariffPlan,

      avgPrice: form.avgPrice,
      inn: form.inn,
      insurerName,

      ...contactData
    };

    try {
      setIsLoading(true);

      await axios.post(`${ENV.REACT_APP_OZON_WEB}/api/ozon/leave-a-claim`, data)
      setIsSuccessfulShowing(true)
    } catch (error) {
      message.error('Ошибка', 4000).then(({ destory }) => {
        setTimeout(() => {
          destory()
        }, 2000)
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isSuccessfulShowing) {
    return <section className="contact-widget__section">
      <div className="container contact-widget__container">
        <div className="contact-widget__progress-block">
          <div className='contact-widget__success-block'>
            <div className='contact-widget__success-description'>
              <div className="contact-widget__success-img">
                <img src={mts} loading="lazy" width={842} height={854} alt="widget" />
              </div>
              <h2 className="title contact-widget__success-title">Заявка отправлена</h2>
              <span className="subtitle contact-widget__success-subtitle">
                Свяжемся с&nbsp;вами в&nbsp;течение часа
              </span>
            </div>
            <button
              className="btn contact-widget__btn"
              onClick={onFinish}>
              Рассчитать ещё
            </button>
          </div>
        </div>
      </div>

    </section>
  }

  return (
    <section className="contact-widget__section">
      <div className="container contact-widget__container">
        <div className="contact-widget__progress-block">
          <form className="contact-widget__form">
            <Progress step={step} stepOptions={stepOptions} disabledSteps={disabledSteps} onStepChange={onStepChange} />
            <h2 className="title contact-widget__title">Как с вами связаться</h2>
            <span className="subtitle contact-widget__form-subtitle">Выберите предпочтительный вид связи</span>
            <div className="calculate__checkbox-menu">
              {OPTIONS.map((item) => {
                return (
                  <div key={item.value} className="checkbox-wrapper-16">
                    <label htmlFor={item.value} className="checkbox-wrapper">
                      <input
                        id={item.value}
                        type="radio"
                        className="checkbox-input"
                        value={item.value}
                        checked={form.contactType === item.value}
                        onChange={handleContactTypeChange(item.value)}
                      />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">{item.label}</span>
                      </span>
                    </label>
                  </div>
                )
              })}
            </div>

            {form.contactType === 'email' &&
              <div className="input">
                <input className="input__input" type="text" placeholder="Введите email" value={form.email} onChange={handleEmailChange} />
              </div>
            }
            {form.contactType === 'phone' && <div className='phone-input'>
              <div className='phone-input__code'>
                <img src={flagRus} loading="lazy" width={18} height={18} alt="rus" />
                +7
              </div>
              <IMaskInput
                value={form.phone}
                className="phone-input__input"
                placeholder="Номер телефона"
                mask='000 000-00-00'
                onAccept={handlePhoneChange('phone')}
              />
            </div>}

            {form.contactType === 'telegram' &&
              <div className='phone-input'>
                <div className='phone-input__code'>
                  <img src={flagRus} loading="lazy" width={18} height={18} alt="rus" />
                  +7
                </div>
                <IMaskInput
                  value={form.telegram}
                  className="phone-input__input"
                  placeholder="Номер телефона"
                  mask='000 000-00-00'
                  onAccept={handlePhoneChange('telegram')}
                />
              </div>
            }
            {form.contactType === 'whatsUp' &&
              <div className='phone-input'>
                <div className='phone-input__code'>
                  <img src={flagRus} loading="lazy" width={18} height={18} alt="rus" />
                  +7
                </div>
                <IMaskInput
                  value={form.whatsUp}
                  className="phone-input__input"
                  placeholder="Номер телефона"
                  mask='000 000-00-00'
                  onAccept={handlePhoneChange('whatsUp')}
                />
              </div>
            }

            <div className="checkbox contact-widget__checkbox">
              <label htmlFor="policy" className="checkbox__label">
                <input id="policy" className="checkbox__input" type="checkbox" checked={isPolicyChecked} onChange={handlePolicyChange} />
                <span className="checkbox__checkmark"></span>
                <span className="checkbox__input-label">
                  Соглашаюсь с&nbsp;правилами обработки персональных данных{' '}
                  <a
                    href="https://files.mainsgroup.ru/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%9F%D0%BD%D0%94.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="checkbox__label_accent">
                    ООО&nbsp;«МСБК»
                  </a>
                  {' '}и&nbsp;даю{' '}
                  <button
                    onClick={handleAgreementModalOpen}
                    className="checkbox__label_accent">
                    согласие
                  </button>
                  {' '}ООО «ОЗОН Капитал» на&nbsp;обработку в&nbsp;соответствии с{' '}
                  <a
                    href="https://docs.ozon.ru/legal/personal-data/ru/il/"
                    target="_blank"
                    rel="noreferrer"
                    className="checkbox__label_accent">
                    Политикой обработки персональных данных
                  </a>
                  . Подтверждаю назначение уполномоченным страховым брокером{' '}
                  <button onClick={handlePolicyModalOpen} className="checkbox__label_accent">ООО «МСБК»</button>
                </span>
              </label>
            </div>
            <button
              type="submit"
              className="btn contact-widget__btn"
              disabled={!isSubmitButtonActive()}
              onClick={handleConfirm}>
              {isLoading ?
                <div className="flex-center">
                  <span className="loader loader_small"></span>
                </div> : 'Продолжить'}
            </button>
          </form>
        </div>
      </div >
      <Modal
        isOpen={isAgreementModalOpen}
        onRequestClose={handleAgreementModalClose}
      >
        <div className="contact-widget__agreement">
          <div className="contact-widget__agreement-text">
            В соответствии с Политикой ООО «ОЗОН Капитал» в отношении обработки персональных данных даю согласие в адрес ООО «ОЗОН Капитал» (далее по тексту – Оператор) на автоматизированную, а также без использования средств автоматизации обработку персональных данных, заполненных в настоящей форме и содержащихся во всех приложенных к ней файлах, в целях получения сведений о направленной мною в адрес ООО «МСБК» » (ОГРН 1157746740876, ИНН 7727230627, зарегистрированному по адресу 125167, г. Москва, Ленинградский проспект, д. 36 стр. 40 пом. 229) заявки на заключение соответствующего договора страхования и/или подбора страхового продукта, а также для осуществления коммуникаций со мной по вопросам и в рамках программы «Ozon Страхование».
            <br /><br />
            Я проинформирован(а), что под обработкой персональных данных понимаются действия (операции) с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача в адрес ООО «МСБК», блокирование, удаление, уничтожение персональных данных.
            <br /><br />
            Передавая персональные данные третьего лица Оператору, подтверждаю и гарантирую наличие согласия третьего лица на такое предоставление данных.
            <br /><br />
            Согласие действует со дня его предоставления до дня отзыва в письменной форме, но в любом случае не более 1 (одного) года со дня предоставления.
            <br /><br />
            Отозвать согласие можно путём направления заявления в свободной форме по адресу Оператора, указав в тексте: ФИО; номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе либо иные данные, позволяющие однозначно идентифицировать субъекта персональных данных; сведения, подтверждающие участие в отношениях с Оператором, либо сведения, иным способом подтверждающие факт обработки персональных данных Оператором; подпись гражданина (или его законного представителя).
            <div className="contact-widget__scroll"></div>
          </div>
          <button className="btn" onClick={handleAgreementModalClose}>Хорошо</button>
        </div>
      </Modal>
      <Modal
        isOpen={isPolicyModalOpen}
        onRequestClose={handlePolicyModalClose}
      >
        <div>
          <div className="contact-widget__agreement-text">
            Настоящим подтверждаю, что страховой брокер ООО «МСБК» назначается уполномоченным брокером по личным видам страхования, включая, но не ограничиваясь ДМС. Подтверждаю, что отменяю все предыдущие назначения, которые были до настоящего момента.
          </div>
          <button className="btn" onClick={handlePolicyModalClose}>Хорошо</button>
        </div>
      </Modal>
    </section >
  );
};

export default ContactWidget;
