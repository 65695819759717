import React from 'react';

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number,
) => {
  const callbackRef = React.useRef(callback);

  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  let timer: number;

  const naiveDebounce = (
    func: (...args: any[]) => void,
    delayMs: number,
    ...args: any[]
  ) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
    }, delayMs);
  };

  return React.useMemo(() => (...args: any) => naiveDebounce(
    callbackRef.current,
    delay,
    ...args,
  ), [delay]);
};