import React from 'react';

import whatsappIcon from '../images/icons/whatsapp_icon.svg';
import telegramIcon from '../images/icons/telegram_icon.svg';
import mainsLogo from '../images/icons/mains-logo.svg';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__contacts">
          <div className="footer__left">
            <h3 className="footer__title">Контакты для связи</h3>
            <div className="footer__phone">
              <a href="tel:+74996862283">7 (499) 686-22-83</a>
              <span>Телефон</span>
            </div>
            <ul className="footer__messengers">
              <li>
                <a className="footer__icon-block icon-block" rel="noreferrer" href="https://wa.me/79859663766" target='_blank' aria-label="telegram">
                  <img src={whatsappIcon} alt="whatsapp" />
                </a>
              </li>
              <li>
                <a className="footer__icon-block icon-block" rel="noreferrer" href="https://t.me/ozon_mains" target='_blank' aria-label="telegram">
                  <img src={telegramIcon} alt="telegram" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__right">
            <a className="mains__logo" href="https://mainsgroup.ru/">
              <img src={mainsLogo} alt="mains" width="128px" />
            </a>
          </div>
        </div>
        <p className="footer__bottom">
          Финансовая услуга по подбору вариантов страхования предоставляется{' '}
          <a className="footer__link" href="https://mainsgroup.ru/">
            ООО «МСБК»
          </a>
          . Лицензия Банка России СБ№4353 выдана 12.08.2021 без ограничения срока действия.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
