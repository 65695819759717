import React, { useEffect, useState } from 'react';
import { message } from 'react-message-popup'
import axios from 'axios';

import CalculateWidget from '../calculate-widget.tsx';
import TariffWidget from '../tariff-widget.tsx';
// import AboutCompanyWidget from '../about-company-widget.tsx';
import ContactWidget from '../contact-widget.tsx';
import { ENV } from '../../common/constants.tsx';
import { STEPS_OPTIONS, StepTypeEnum } from './progress.tsx';

export type DisabledSteps = { [key in StepTypeEnum]: boolean };
export interface Form {
  employeesCount: string,
  cityNames: Array<string>,
  newCities: Array<{ value: string; name: string }>,
  tariffPlan: string,
  medicalCareTypes: Array<string>,
  inn?: string,
  avgPrice?: string,
  insurerName?: string,
  phone?: string,
  email?: string,
  contactType: string,
  whatsUp?: string,
  telegram?: string
}

const Calculate: React.FC = () => {
  const [step, setStep] = useState<StepTypeEnum>(StepTypeEnum.price);
  const [insurerOffers, setInsurerOffers] = useState<Array<{ value: StepTypeEnum, label: string, }>>([]);
  const [isInsurerOffersLoading, setIsInsurerOffersLoading] = useState<boolean>(false);

  const initialForm: Form = {
    employeesCount: '',
    cityNames: [],
    newCities: [],
    tariffPlan: 'Standard',
    medicalCareTypes: [],
    // inn: '',
    insurerName: '',
    avgPrice: '',
    contactType: 'phone'
  }
  const [form, setForm] = useState(initialForm);

  const initialDisabledSteps = {
    [StepTypeEnum.price]: true,
    [StepTypeEnum.tariff]: true,
    // [StepTypeEnum.data]: true,
    [StepTypeEnum.contacts]: true,
  }
  const [disabledSteps, setDisabledSteps] = useState<DisabledSteps>(initialDisabledSteps);

  const handleSetFormValues = (values) => {
    setForm({ ...form, ...values })
  }

  useEffect(() => {
    setDisabledSteps(initialDisabledSteps);

  }, [form.employeesCount, form.cityNames.length, form.newCities?.length])

  useEffect(() => {
    if (form.avgPrice === '') {
      return;
    }

    const newDisabledStepsValue = {
      [StepTypeEnum.price]: false,
      [StepTypeEnum.tariff]: true,
      // [StepTypeEnum.data]: true,
      [StepTypeEnum.contacts]: true,
    }

    setDisabledSteps(newDisabledStepsValue);

  }, [form.medicalCareTypes.length, form.tariffPlan])

  const handleSetForm = (field: string, value) => {
    setForm({ ...form, [field]: value })
  }

  const handleFinishClick = () => {
    setDisabledSteps(initialDisabledSteps);
    setStep(StepTypeEnum.price);
    setForm(initialForm);
  }

  const handleFirstStepChange = (value: StepTypeEnum) => {
    setStep(value);


    const data = {
      cityNames: [...form.cityNames, ...form.newCities.map(item => item.value)],
      employeesCount: form.employeesCount,
      tariffPlan: form.tariffPlan,
      medicalCareTypes: form.medicalCareTypes,
    };

    getOffers(data);
  }

  const getOffers = async (data, callback?) => {
    try {
      setIsInsurerOffersLoading(true);

      const response = await axios.post(`${ENV.REACT_APP_OZON_WEB}/api/ozon/calculate-insurer-offers`, data)
      setInsurerOffers(response.data.insurerOffers);
      if (callback) {
        callback()
      }
    } catch (error) {
      message.error('Ошибка', 4000).then(({ destory }) => {
        setTimeout(() => {
          destory()
        }, 2000)
      });
    } finally {
      setIsInsurerOffersLoading(false);
    }
  }

  const renderStep = () => {
    switch (step) {
      case StepTypeEnum.price:
        return <CalculateWidget
          form={form}
          isInsurerOffersLoading={isInsurerOffersLoading}
          step={step}
          stepOptions={STEPS_OPTIONS}
          disabledSteps={disabledSteps}
          onStepChange={handleFirstStepChange}
          onSetForm={handleSetForm}
          onNextStep={() => {
            setStep(StepTypeEnum.tariff);
            setDisabledSteps({ ...disabledSteps, [StepTypeEnum.price]: false })
          }}
          getOffers={getOffers}
          onSetFormValues={handleSetFormValues}
        />
      case StepTypeEnum.tariff:
        return <TariffWidget
          form={form}
          insurerOffers={insurerOffers}
          isInsurerOffersLoading={isInsurerOffersLoading}
          step={step}
          stepOptions={STEPS_OPTIONS}
          disabledSteps={disabledSteps}
          onStepChange={setStep}
          onSetForm={handleSetForm}
          onSetFormValues={handleSetFormValues}
          onNextStep={() => {
            setStep(StepTypeEnum.contacts);
            setDisabledSteps({ ...disabledSteps, [StepTypeEnum.tariff]: false })
          }}
          getOffers={getOffers}
        />
      // case StepTypeEnum.data:
      //   return <AboutCompanyWidget
      //     form={form}
      //     step={step}
      //     stepOptions={STEPS_OPTIONS}
      //     disabledSteps={disabledSteps}
      //     onStepChange={setStep}
      //     onSetForm={handleSetForm}
      //     onNextStep={() => {
      //       setStep(StepTypeEnum.contacts);
      //       setDisabledSteps({ ...disabledSteps, [StepTypeEnum.data]: false })
      //     }}
      //   />
      case StepTypeEnum.contacts:
        return <ContactWidget
          form={form}
          step={step}
          stepOptions={STEPS_OPTIONS}
          disabledSteps={disabledSteps}
          onStepChange={setStep}
          onSetForm={handleSetForm}
          onSetFormValues={handleSetFormValues}
          onFinish={handleFinishClick}
        />
      default:
        return null
    }
  }

  return (
    <div id="calculate-section">
      {renderStep()}
    </div>
  )
};

export default Calculate;
